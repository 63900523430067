import "./bootstrap";
import "../css/app.css";

import { createApp } from "vue/dist/vue.esm-bundler.js";
import { UtilitiesVue } from "./Helpers/Utilities";
import i18n from "./Services/Lang.js";
import Search from "./Views/Search.vue";
import Offers from "./Views/Offers.vue";
import Offer from "./Views/Offer.vue";
import CartOffer from "./Views/CartOffer.vue";
import Checkout from "./Views/Checkout.vue";
import CheckoutPayment from "./Views/CheckoutPayment.vue";
import CheckoutPaymentResponse from "./Views/CheckoutPaymentResponse.vue";
import CheckoutSuccess from "./Views/CheckoutSuccess.vue";
import Newsletter from "./Views/Newsletter.vue";
import Loading from "./Components/Global/Loading.vue";
import router from "./Routes/index.js";
import { createPinia } from "pinia";

const app = createApp({
    components: {
        Search,
        Offers,
        CartOffer,
        Offer,
        Checkout,
        CheckoutSuccess,
        CheckoutPayment,
        CheckoutPaymentResponse,
        Newsletter,
        Loading,
    },
    delimiters: ["${", "}"],
})
    .use(i18n)
    .use(createPinia())
    .use(router)
    .use(UtilitiesVue);
app.mount("#app");

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}
