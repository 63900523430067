import { defineStore } from "pinia";

export const useOrderStore = defineStore("order", () => {

    const order = ref(null);

    const content = ref(null);

    const loadData = (orderId) => {
        return axios.get('/api/checkout/' + orderId)
            .then((response) => {
                order.value = response.data.order;
                content.value = response.data.content;
                return response.data.order.total;
            });
    };

    const itemOffer = computed(() => {
        return order.value.items.find(item => item.product_type == 'offer');
    });

    return {
        order,
        content,
        itemOffer,
        loadData,
    }
});
