import { defineStore } from "pinia";

export const useAirportStore = defineStore("airportStore", () => {

    const availibleDepartureAirports = ref([]);

    const loadData = (params) => {
        if (params.productType != 'pauschal') {
            return;
        }

        return axios.get('/api/airports', {
            params: params
        }).then((response) => {
            availibleDepartureAirports.value = response.data;
        });
    };

    return {
        availibleDepartureAirports,
        loadData,
    }
});
